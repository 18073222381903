import React, { useState, useEffect } from "react";
import backgroundCover from "../../../assets/learningBackground.png";
import Mask from "../../../assets/dots-rectangle.svg";
import { motion } from "framer-motion";
import axios from "axios";

const FAQ = () => {
  const [faqData, setFaqData] = useState({});

  useEffect(() => {
    const getNavData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/Form`
        );
        console.log("responseresponseFAQ", response?.data?.data[0]);
        setFaqData(response?.data?.data[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getNavData();
  }, []);
  console.log(
    "faqDatafaqData",
    faqData &&
      faqData.fields &&
      faqData &&
      faqData.fields &&
      faqData?.fields[0].name
  );
  // console.log("faqDatafaqData", faqData)
  const backgroundStyle = {
    backgroundImage: `url(${backgroundCover})`,
    backgroundSize: "cover",
    zIndex: "0",
  };
  const dropShadowFilter = "drop-shadow(43px -33px 0px #E5EDFF)";
  return (
    <div className="lg:relative lg:h-[550px] w-[95%] lg:w-full mx-auto lg:mx-0">
      <div>
        <div
          className="pt-20 inset-0 lg:flex hidden"
          style={backgroundStyle}
        ></div>
      </div>
      <div className="flex items-center relative lg:h-[500px] lg:pl-20">
        <div className="absolute bottom-3 -left-5 overflow-hidden lg:flex hidden">
          <img src={Mask} alt="Mask" />
        </div>
        <div className="absolute top-3 -right-0 z-[-1]">
          <img src={Mask} alt="Mask" />
        </div>
        <div className="absolute top-10 left-10 lg:flex hidden">
          <svg
            width="83"
            height="83"
            viewBox="0 0 83 83"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="83"
              y="83"
              width="83"
              height="83"
              rx="41.5"
              transform="rotate(180 83 83)"
              fill="#E9EFFF"
            />
          </svg>
        </div>
        <div className="absolute top-14 md:left-[600px] lg:flex hidden">
          <svg
            width="55"
            height="55"
            viewBox="0 0 55 55"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="55"
              y="55"
              width="55"
              height="55"
              rx="27.5"
              transform="rotate(180 55 55)"
              fill="#E9EFFF"
            />
          </svg>
        </div>
        <div className="absolute top-[300px] -right-0 lg:flex hidden">
          <svg
            width="34"
            height="38"
            viewBox="0 0 34 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="38"
              y="38"
              width="38"
              height="38"
              rx="19"
              transform="rotate(180 38 38)"
              fill="#E9EFFF"
            />
          </svg>
        </div>
        <div className="lg:w-[40%] p-0 px-4">
          {/* <div className="font-[800] text-[24.38px] md:text-[40px] leading-[33.31px] md:leading-[54.64px] mb-2 text-start">Have A Question Or Looking To Book A Private Training?</div> */}
          <div className="font-[800] text-[24.38px] md:text-[40px] leading-[33.31px] md:leading-[54.64px] mb-2 text-start">
            {faqData.heading}
          </div>
          {/* <div className="text-[12.44px] md:text-[20px] leading-[16.92px] md:leading-[27.2px] text-[#666666] ">Please fill in our inquiry for more information</div> */}
          <div className="text-[12.44px] md:text-[20px] leading-[16.92px] md:leading-[27.2px] text-[#666666] ">
            {faqData.sub_heading}
          </div>
        </div>
      </div>
      <div className="lg:absolute lg:top-16 lg:right-[50px] lg:z-[999] flex justify-center">
        <motion.div
          // initial={{ x: -100, opacity: 0 }}
          // whileInView={{ x: 0, opacity: 1 }}
          // transition={{ duration: 1 }}
          className="w-[90%] xl:w-[658px] lg:max-h-[581px] bg-white my-10 px-10 shadow-md rounded-[10px] m-5 lg:m-0"
          style={{ boxShadow: "0 -2px 10px 0 rgba(0, 0, 0, 0.2)" }}
        >
          <div className="w-full py-1">
            {/* <div className="w-full text-[#0E0E0E] leading-[46.8px] text-[20.19px] md:text-[36px] font-[600] flex justify-center">Enter Your Details</div> */}
            <div className="w-full text-[#0E0E0E] leading-[46.8px] text-[20.19px] md:text-[36px] font-[600] flex justify-center">
              {faqData?.form_heading}
            </div>
            <form className="w-full pb-4">
              <div className="lg:flex justify-between w-full gap-8 mb-0 lg:mb-4 mt-4">
                <div className="form-control w-full mb-8 lg:mb-0">
                  <input
                    className="text-[10.09px] md:text-[18px]"
                    type="value"
                    required
                  />
                  <label>
                    {faqData &&
                      faqData.fields &&
                      faqData.fields[0].placeholder
                        .split("")
                        .map((char, index) => <span key={index}>{char}</span>)}
                    {/* <span style={{ transitionDelay: '50ms' }}>{faqData && faqData.fields && faqData?.fields[0].placeholder}</span> */}
                    {/* <span style={{ transitionDelay: '0ms' }}>F</span>
                  <span style={{ transitionDelay: '50ms' }}>i</span>
                  <span style={{ transitionDelay: '100ms' }}>r</span>
                  <span style={{ transitionDelay: '150ms' }}>s</span>
                  <span style={{ transitionDelay: '200ms' }}>t</span>
                  <span style={{ transitionDelay: '200ms' }}>{" "}</span>
                  <span style={{ transitionDelay: '250ms' }}>N</span>
                  <span style={{ transitionDelay: '300ms' }}>a</span>
                  <span style={{ transitionDelay: '350ms' }}>m</span>
                  <span style={{ transitionDelay: '400ms' }}>e</span> */}
                  </label>
                </div>
                <div className="form-control w-full mb-8 lg:mb-0">
                  <input
                    className="text-[10.09px] md:text-[18px]"
                    type="value"
                    required
                  />
                  <label>
                    {faqData &&
                      faqData.fields &&
                      faqData.fields[1].placeholder
                        .split("")
                        .map((char, index) => <span key={index}>{char}</span>)}
                    {/* <span style={{ transitionDelay: '50ms' }}>{faqData && faqData.fields && faqData?.fields[1].placeholder}</span> */}
                    {/* <span style={{ transitionDelay: '0ms' }}>L</span>
                    <span style={{ transitionDelay: '50ms' }}>a</span>
                    <span style={{ transitionDelay: '100ms' }}>s</span>
                    <span style={{ transitionDelay: '150ms' }}>t</span>
                    <span style={{ transitionDelay: '200ms' }}>{" "}</span>
                    <span style={{ transitionDelay: '250ms' }}>N</span>
                    <span style={{ transitionDelay: '300ms' }}>a</span>
                    <span style={{ transitionDelay: '350ms' }}>m</span>
                    <span style={{ transitionDelay: '400ms' }}>e</span> */}
                  </label>
                </div>
              </div>
              <div className="lg:flex justify-between w-full gap-8 mt-0 mb-0 lg:mb-4 lg:mt-4">
                <div className="form-control w-full mb-8 lg:mb-0">
                  <input
                    className="text-[10.09px] md:text-[18px]"
                    type="value"
                    required
                  />
                  <label>
                    {faqData &&
                      faqData.fields &&
                      faqData.fields[2].placeholder
                        .split("")
                        .map((char, index) => <span key={index}>{char}</span>)}
                    {/* <span style={{ transitionDelay: '50ms' }}>{faqData && faqData.fields && faqData?.fields[2].placeholder}</span> */}
                    {/* <span style={{ transitionDelay: '0ms' }}>E</span>
                    <span style={{ transitionDelay: '50ms' }}>m</span>
                    <span style={{ transitionDelay: '100ms' }}>a</span>
                    <span style={{ transitionDelay: '150ms' }}>i</span>
                    <span style={{ transitionDelay: '200ms' }}>l</span>
                    <span style={{ transitionDelay: '200ms' }}>*</span> */}
                  </label>
                </div>
                <div className="form-control w-full  mb-8 lg:mb-0">
                  <input
                    className="text-[10.09px] md:text-[18px]"
                    type="value"
                    required
                  />
                  <label>
                    {faqData &&
                      faqData.fields &&
                      faqData.fields[3].placeholder
                        .split("")
                        .map((char, index) => <span key={index}>{char}</span>)}
                    {/* <span style={{ transitionDelay: '50ms' }}>{faqData && faqData.fields && faqData?.fields[3].placeholder}</span> */}
                    {/* <span style={{ transitionDelay: '0ms' }}>P</span>
                    <span style={{ transitionDelay: '50ms' }}>h</span>
                    <span style={{ transitionDelay: '100ms' }}>o</span>
                    <span style={{ transitionDelay: '150ms' }}>n</span>
                    <span style={{ transitionDelay: '200ms' }}>e</span> */}
                  </label>
                </div>
              </div>
              <div className="flex justify-between w-full gap-8 mb-6 mt-4">
                <div className="form-control w-full">
                  <input
                    className="text-[10.09px] md:text-[18px]"
                    type="value"
                    required
                  />
                  <label>
                    {faqData &&
                      faqData.fields &&
                      faqData.fields[4].placeholder
                        .split("")
                        .map((char, index) => <span key={index}>{char}</span>)}
                    {/* <span style={{ transitionDelay: '50ms' }}>{faqData && faqData.fields && faqData?.fields[4].placeholder}</span> */}
                    {/* <span style={{ transitionDelay: '0ms' }}>L</span>
                    <span style={{ transitionDelay: '50ms' }}>o</span>
                    <span style={{ transitionDelay: '100ms' }}>c</span>
                    <span style={{ transitionDelay: '150ms' }}>a</span>
                    <span style={{ transitionDelay: '200ms' }}>t</span>
                    <span style={{ transitionDelay: '250ms' }}>i</span>
                    <span style={{ transitionDelay: '300ms' }}>o</span>
                    <span style={{ transitionDelay: '350ms' }}>n</span> */}
                  </label>
                </div>
              </div>
              <div className="flex justify-between w-full gap-8 mb-6 mt-4">
                <div className="form-control w-full">
                  <input
                    className="text-[10.09px] md:text-[18px]"
                    type="value"
                    required
                  />
                  <label>
                    {faqData &&
                      faqData.fields &&
                      faqData.fields[5].placeholder
                        .split("")
                        .map((char, index) => <span key={index}>{char}</span>)}
                    {/* <span style={{ transitionDelay: '50ms' }}>{faqData && faqData.fields && faqData?.fields[5].placeholder}</span> */}
                    {/* <span style={{ transitionDelay: '0ms' }}>T</span>
                      <span style={{ transitionDelay: '50ms' }}>r</span>
                      <span style={{ transitionDelay: '100ms' }}>a</span>
                      <span style={{ transitionDelay: '150ms' }}>i</span>
                      <span style={{ transitionDelay: '200ms' }}>n</span>
                      <span style={{ transitionDelay: '250ms' }}>i</span>
                      <span style={{ transitionDelay: '300ms' }}>n</span>
                      <span style={{ transitionDelay: '350ms' }}>g</span>
                      <span style={{ transitionDelay: '400ms' }}>{" "}</span>
                      <span style={{ transitionDelay: '450ms' }}>T</span>
                      <span style={{ transitionDelay: '500ms' }}>o</span>
                      <span style={{ transitionDelay: '550ms' }}>p</span>
                      <span style={{ transitionDelay: '600ms' }}>i</span>
                      <span style={{ transitionDelay: '650ms' }}>c</span>
                      <span style={{ transitionDelay: '700ms' }}>s</span> */}
                  </label>
                </div>
              </div>
              <div className="form-control w-full mt-4">
                <input
                  className="text-[10.09px] md:text-[18px]"
                  type="value"
                  required
                />
                <label>
                  {faqData &&
                    faqData.fields &&
                    faqData.fields[6].placeholder
                      .split("")
                      .map((char, index) => <span key={index}>{char}</span>)}
                  {/* <span style={{ transitionDelay: '50ms' }}>{faqData && faqData.fields && faqData?.fields[6].placeholder}</span> */}
                  {/* <span style={{ transitionDelay: '0ms' }}>A</span>
                    <span style={{ transitionDelay: '50ms' }}>d</span>
                    <span style={{ transitionDelay: '100ms' }}>d</span>
                    <span style={{ transitionDelay: '150ms' }}>i</span>
                    <span style={{ transitionDelay: '200ms' }}>t</span>
                    <span style={{ transitionDelay: '250ms' }}>i</span>
                    <span style={{ transitionDelay: '300ms' }}>o</span>
                    <span style={{ transitionDelay: '350ms' }}>n</span>
                    <span style={{ transitionDelay: '400ms' }}>a</span>
                    <span style={{ transitionDelay: '450ms' }}>l</span>
                    <span style={{ transitionDelay: '500ms' }}>{" "}</span>
                    <span style={{ transitionDelay: '550ms' }}>N</span>
                    <span style={{ transitionDelay: '600ms' }}>o</span>
                    <span style={{ transitionDelay: '650ms' }}>t</span>
                    <span style={{ transitionDelay: '700ms' }}>e</span>
                    <span style={{ transitionDelay: '750ms' }}>s</span> */}
                </label>
              </div>
              <div className="flex justify-end mt-4">
                <motion.div whileHover={{ scale: 1.05 }}>
                  <button className="bg-[#B02526] w-[100px] py-2 rounded-[6px] font-[600] text-white text-[17px] leading-[28px]  transition-colors duration-300 hover:bg-blue-500 shadow-custom transform hover:scale-110 transition-transform duration-300">
                    Send
                  </button>
                </motion.div>
              </div>
            </form>
          </div>
        </motion.div>
      </div>
    </div>
  );
};
export default FAQ;
