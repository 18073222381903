import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const Kit = ({content}) => {
  const [elements, setElements] = useState([])
  useEffect(() => {
    if (content) {
      const ele = content?.description?.split(',');
      setElements(ele)
    }
  }, [content])
  console.log('elementselementselementselements', elements)
  return (
    <>
      <div className="font-[800] text-[20px] leading-[25.2px] text-[#183864]">{content?.title} CONTENTS OF KIT</div>
      <div class="mt-4 ml-5">
        <ul class="list-disc font-[500] text-[12px] lg:text-[20px] leading-[28px] lg:leading-[45px] text-[#5E6282]">
          {elements.length > 0 &&
            elements.map((item, index) => (
              <li>{item}asdkjhkjsd</li>
            ))
          }
        </ul>
      </div>
    </>
  )
}
export default Kit;
