import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from 'axios';

const Navbar = () => {
  const [navData, setNavData] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    const getNavData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/navbar`);
        console.log(response.data.data, "response data");
        const sortedNavData = [...response.data.data].sort((a, b) => a.order - b.order);
        setNavData(sortedNavData);
        setIsDataLoaded(true); // Set the flag to true once data is loaded
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Check if navData is empty and if data is not already loaded
    if (navData.length === 0 && !isDataLoaded) {
      getNavData();
    }
  }, [navData, isDataLoaded]);

  const location = useLocation();

  const isActive = (path) => {
    return location.pathname.includes(path);
  };

  return (
    <div className="p-4 bg-[#073967] min-h-[60px]">
      <div className="flex items-center justfify-center w-[55%] m-auto">
        <div className="flex justify-between w-full">
          {navData.length > 0 &&
            navData.map((item, index) => (
              <Link
                to={item.url}
                className={`nav-link ${isActive(`${item.url}`) ? 'text-white' : 'text-[#9A9EA6]'} font-medium text-[16px] leading-[24px] cursor-pointer`}
              >
                {item.name}
              </Link>
            ))

          }
        </div>
      </div>
    </div>
  )
}
export default Navbar;
