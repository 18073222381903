import React, { useState, useEffect } from "react";
import { formatDate } from "../common/dateFormatter";

const RegistrationForm = ({ onSubmit, initialValues }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    phone: "",
    termsConditions: false,
    codeOfConduct: false,
    useInfoForAll: false,
    isEnrolled: false,
    ...initialValues,
  });

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      ...initialValues,
    }));
  }, [initialValues]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

const handleSubmit = (e) => {
  e.preventDefault();
  const updatedFormData = {
    ...formData,
    dateAttended: formatDate(new Date()),
  };
  onSubmit(updatedFormData);
};


  return (
    <form
      onSubmit={handleSubmit}
      className="space-y-4 bg-white p-5 mb-1 shadow-md"
    >
      {/* Render only if the event has not been taken before */}
      {!formData.isEnrolled && (
        <>
          <div>
            <label
              htmlFor="firstName"
              className="block text-sm font-medium text-gray-700"
            >
              First Name *
            </label>
            <input
              type="text"
              name="firstName"
              id="firstName"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={formData.firstName}
              onChange={handleChange}
              required={!formData.isEnrolled}
            />
          </div>
          <div>
            <label
              htmlFor="lastName"
              className="block text-sm font-medium text-gray-700"
            >
              Last Name *
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={formData.lastName}
              onChange={handleChange}
              required={!formData.isEnrolled}
            />
          </div>
          <div>
            <label
              htmlFor="phone"
              className="block text-sm font-medium text-gray-700"
            >
              Cell Phone Number *
            </label>
            <input
              type="tel"
              name="phone"
              id="phone"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={formData.phone}
              onChange={handleChange}
              required={!formData.isEnrolled}
            />
          </div>
        </>
      )}

      <div>
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-700"
        >
          Email Address *
        </label>
        <input
          type="email"
          id="email"
          name="email"
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label
          htmlFor="confirmEmail"
          className="block text-sm font-medium text-gray-700"
        >
          Re-enter Email Address *
        </label>
        <input
          type="email"
          name="confirmEmail"
          id="confirmEmail"
          required
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          value={formData.confirmEmail}
          onChange={handleChange}
        />
      </div>

      {/* Checkbox Already taken the event */}
      <div>
        <label className="flex items-center space-x-2">
          <input
            type="checkbox"
            name="isEnrolled"
            checked={formData.isEnrolled}
            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
            onChange={handleChange}
          />
          <span className="text-sm text-gray-700">
            I have already taken this event before
          </span>
        </label>
      </div>

      {/* Render only if the event has not been taken before */}
      {!formData.isEnrolled && (
        <>
          <div>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                name="termsConditions"
                checked={formData.termsConditions}
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                onChange={handleChange}
                required={!formData.isEnrolled}
              />
              <span className="text-sm text-gray-700">
                Agreed - Terms and Conditions *
              </span>
            </label>
          </div>
          <div>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                name="codeOfConduct"
                checked={formData.codeOfConduct}
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                onChange={handleChange}
                required={!formData.isEnrolled}
              />
              <span className="text-sm text-gray-700">
                Agreed - Code of Conduct *
              </span>
            </label>
          </div>
          <div>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                name="useInfoForAll"
                checked={formData.useInfoForAll}
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                onChange={handleChange}
              />
              <span className="text-sm text-gray-700">
                Use Attendee's information for All Attendees
              </span>
            </label>
          </div>
        </>
      )}
      <button
        type="submit"
        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Submit
      </button>
    </form>
  );
};

export default RegistrationForm;
