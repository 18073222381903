import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm, useFieldArray } from "react-hook-form";
import { Disclosure } from "@headlessui/react";
import { formatDate } from "../../common/dateFormatter";
import { FiChevronUp, FiChevronDown, FiPlus, FiMinusCircle } from "react-icons/fi";
import axios from 'axios';
import { toast } from 'react-toastify';

const AttendeeRegistrationForm = () => {
    const [totalSeats,setTotalSeats]=useState(0)
    const { eventId, maxSeats } = useParams();
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            attendees: [
                {
                    firstName: "",
                    lastName: "",
                    email: "",
                    confirmEmail: "",
                    phone: "",
                },
            ],
        },
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "attendees",
    });

    useEffect(() => {
        if (maxSeats) {
            const decodedString = atob(maxSeats);
            const decryptedSeats = parseInt(decodedString, 10); 
            setTotalSeats(decryptedSeats)
        }
    }, [maxSeats])
    const onSubmitForm = async (data) => {
        const formattedAttendees = data.attendees.map(attendee => ({
            ...attendee,
            dateAttended: formatDate(new Date())
        }));

        const payload = {
            attendees: {
                [eventId]: formattedAttendees
            }
        };

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/event/addAttendees`,
                payload
            );
            if (response.status === 201) {
                toast.success(
                    "Attendees added successfully"
                );
                console.log("Attendees added successfully:", response.data);
                reset()
                navigate("/")
            }
        } catch (error) {
            console.error("Error adding the attendees!", error);
            toast.error(
                "There was an error adding the attendees, please try again later."
            );
        }
    };


    const addAttendee = () => {
        if (fields.length < totalSeats) {
            append({
                firstName: "",
                lastName: "",
                email: "",
                confirmEmail: "",
                phone: "",
            });
        }
    };

    return (
        <div className="max-w-6xl mx-auto p-5 bg-white shadow-lg rounded-lg mt-5">
            <form onSubmit={handleSubmit(onSubmitForm)} className="space-y-4">
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-2xl font-bold text-gray-700">Attendee Registration</h2>
                    {fields.length < totalSeats && (
                        <button
                            type="button"
                            onClick={addAttendee}
                            className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
                        >
                            <span> Add Attendee</span> <FiPlus className="size-5" />
                        </button>
                    )}
                </div>

                {fields.map((item, index) => (
                    <Disclosure key={item.id}>
                        {({ open }) => (
                            <div className="border rounded-lg overflow-hidden mb-4 shadow-sm">
                                <Disclosure.Button className="w-full flex justify-between items-center px-4 py-3 bg-gray-100 text-gray-700 hover:bg-gray-200 transition">
                                    <span>{`Attendee ${index + 1}`}</span>
                                    <div className="flex items-center gap-2">
                                        {open ? (
                                            <FiChevronUp className="size-5" />
                                        ) : (
                                            <FiChevronDown className="size-5" />
                                        )}
                                        {fields.length > 1 && (
                                            <button
                                                type="button"
                                                className="flex items-center justify-center"
                                                onClick={() => remove(index)}
                                            >
                                                <FiMinusCircle className="size-5" />
                                            </button>
                                        )}
                                    </div>
                                </Disclosure.Button>
                                <Disclosure.Panel className="p-4 bg-white">
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                        <div className="space-y-4">
                                            <div>
                                                <input
                                                    type="text"
                                                    placeholder="First Name"
                                                    {...register(`attendees.${index}.firstName`, { required: "First name is required" })}
                                                    className="w-full border border-gray-300 rounded-md p-2"
                                                />
                                                {errors?.attendees?.[index]?.firstName && (
                                                    <span className="text-red-500 text-sm">{errors.attendees[index].firstName.message}</span>
                                                )}
                                            </div>

                                            <div>
                                                <input
                                                    type="email"
                                                    placeholder="Email"
                                                    {...register(`attendees.${index}.email`, {
                                                        required: "Email is required",
                                                        pattern: { value: /^\S+@\S+$/i, message: "Invalid email address" },
                                                    })}
                                                    className="w-full border border-gray-300 rounded-md p-2"
                                                />
                                                {errors?.attendees?.[index]?.email && (
                                                    <span className="text-red-500 text-sm">{errors.attendees[index].email.message}</span>
                                                )}
                                            </div>
                                            <div>
                                                <input
                                                    type="tel"
                                                    placeholder="Phone"
                                                    {...register(`attendees.${index}.phone`, { required: "Phone number is required" })}
                                                    className="w-full border border-gray-300 rounded-md p-2"
                                                />
                                                {errors?.attendees?.[index]?.phone && (
                                                    <span className="text-red-500 text-sm">{errors.attendees[index].phone.message}</span>
                                                )}
                                            </div>
                                        </div>

                                        <div className="space-y-4">
                                            <div>
                                                <input
                                                    type="text"
                                                    placeholder="Last Name"
                                                    {...register(`attendees.${index}.lastName`, { required: "Last name is required" })}
                                                    className="w-full border border-gray-300 rounded-md p-2"
                                                />
                                                {errors?.attendees?.[index]?.lastName && (
                                                    <span className="text-red-500 text-sm">{errors.attendees[index].lastName.message}</span>
                                                )}
                                            </div>
                                            <div>
                                                <input
                                                    type="email"
                                                    placeholder="Confirm Email"
                                                    {...register(`attendees.${index}.confirmEmail`, {
                                                        required: "Confirm email is required",
                                                        validate: (value, formValues) =>
                                                            value === formValues.attendees[index]?.email || "Emails do not match",
                                                    })}
                                                    className="w-full border border-gray-300 rounded-md p-2"
                                                />
                                                {errors?.attendees?.[index]?.confirmEmail && (
                                                    <span className="text-red-500 text-sm">{errors.attendees[index].confirmEmail.message}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Disclosure.Panel>
                            </div>
                        )}
                    </Disclosure>
                ))}

                <button
                    type="submit"
                    className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 transition"
                >
                    Submit
                </button>
            </form>
        </div>
    );
};

export default AttendeeRegistrationForm;
