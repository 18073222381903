import React, { useEffect, useState } from "react";
import { motion } from 'framer-motion';

const QuestionSection = ({ question, answer }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded((prev) => !prev);
  };
  useEffect(() => {
    if (question === 'What are your COVID-19 protocols?') {
      setIsExpanded(true);
    }
  }, [])
  return (
    <div className={`py-4 border-b-[1px] border-[#D9DBE9] ${isExpanded ? '' : ''}`}>
      <div className="flex items-center justify-between text-[#183864]">
        <div className={`font-[500] text-[12px] lg:text-[22px] leading-[28px] ${isExpanded ? 'text-[#170F49]' : 'text-[#183864]'}`}>
          {question}
        </div>
        <div className="cursor-pointer" onClick={toggleExpansion}>
          {isExpanded ?
          <svg width="17" height="3" viewBox="0 0 17 3" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.46289 1.95483H15.4629" stroke="#183864" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          :
          <svg className="hover:rotate-90 duration-300" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.46289 1.95483V15.9548" stroke="#170F49" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1.46289 8.95483H15.4629" stroke="#170F49" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          }
        </div>
      </div>
      {isExpanded && (
        <div className="font-[400] text-[10px] lg:text-[18px] leading-[14.84px] md:leading-[15.9px] lg:leading-[30px] text-[#6F6C90] py-2 lg:py-4 overflow-hidden">
          {answer}
          <span className="font-[500] text-[#183864] ml-1">https://www.prosafetraining.ca/communicable-disease-plan/</span>
        </div>
      )}
    </div>
  );
};

const FrequentlyAskQuestion = ({faqData}) => {
  console.log("faqDatafaqDatafaqData", faqData)
  const [selectedTab, setSelectedTab] = useState("General")
  return (
    <div className="flex flex-col items-center w-full justify-center">
      <div className="flex justify-center lg:justify-start gap-7 my-6">
        <div className="h-[34.58px] lg:h-[68px] w-[85.44px] lg:w-[168px]" onClick={() => setSelectedTab("General")}>
          <motion.div whileHover={{ scale: 1.05 }} className={`flex items-center justify-center h-full ${selectedTab === "General" ? "bg-[#B02526] text-white" : "text-[#6F6C90]"} rounded-[7.12px] lg:rounded-[14px] border-[1px] border-[#D9DBE9] cursor-pointer text-[10.17px] lg:text-[20px] font-[400] leading-[28px]`}>General</motion.div>
        </div>
        <div className="h-[34.58px] lg:h-[68px] w-[85.44px] lg:w-[168px]" onClick={() => setSelectedTab("First Aid")}>
          <motion.div whileHover={{ scale: 1.05 }} className={`flex items-center justify-center h-full ${selectedTab === "First Aid" ? "bg-[#B02526] text-white" : "text-[#6F6C90]"} rounded-[7.12px] lg:rounded-[14px] border-[1px] border-[#D9DBE9] cursor-pointer text-[10.17px] lg:text-[20px] font-[400] leading-[28px]`}>First Aid</motion.div>
        </div>
        <div className="h-[34.58px] lg:h-[68px] w-[85.44px] lg:w-[168px]" onClick={() => setSelectedTab("Campus")}>
          <motion.div whileHover={{ scale: 1.05 }} className={`flex items-center justify-center h-full ${selectedTab === "Campus" ? "bg-[#B02526] text-white" : "text-[#6F6C90]"} rounded-[7.12px] lg:rounded-[14px] border-[1px] border-[#D9DBE9] cursor-pointer text-[10.17px] lg:text-[20px] font-[400] leading-[28px]`}>Campus</motion.div>
        </div>
      </div>
      <motion.div
        // initial={{ x: 100, opacity: 0 }}
        // whileInView={{ x: 0, opacity: 1 }}
        // transition={{ duration: 1 }}
        className="max-w-[878px] shadow-md py-10 px-10 xl:px-16 rounded-[18px] mb-10"
        style={{ boxShadow: '0 -2px 10px 0 rgba(0, 0, 0, 0.2)' }}
      >
        <div className="">
          <div className="border-b-[1px] border-[#D9DBE9] min-w-[600px]">
            {
              faqData?.map((item, index) => (
                item.category === selectedTab &&
                  <QuestionSection
                    question={item.faq.question}
                    answer={item.faq.answer}
                  />
              ))
            }
            {/* <QuestionSection
              question="What are your COVID-19 protocols?"
              answer="The health and safety of our instructors and students is a top priority at ProSafe. For the most up to date protocols and policies please visit"
            />
            <QuestionSection
              question="Why are the course times so strict?"
              answer="The health and safety of our instructors and students is a top priority at ProSafe. For the most up to date protocols and policies please visit"
            />
            <QuestionSection
              question="What happens if Prosafe cancels a course?"
              answer="The health and safety of our instructors and students is a top priority at ProSafe. For the most up to date protocols and policies please visit"
            />
            <QuestionSection
              question="Lost Certificates"
              answer="The health and safety of our instructors and students is a top priority at ProSafe. For the most up to date protocols and policies please visit"
            />
            <QuestionSection
              question="Are courses available in different languages?"
              answer="The health and safety of our instructors and students is a top priority at ProSafe. For the most up to date protocols and policies please visit"
            />
            <QuestionSection
              question="What are ProSafe expectations of participants?"
              answer="The health and safety of our instructors and students is a top priority at ProSafe. For the most up to date protocols and policies please visit"
            /> */}
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default FrequentlyAskQuestion;
