import React, { useState } from "react";
import ButtonVector from '../../../assets/buttonVector.svg';
import FrequentlyAskQuestion from './FrequentlyAskQuestion';
import ImageSection from './ImageSection';
import Mask from '../../../assets/dots-rectangle.svg';
import {motion} from 'framer-motion'
import axios from 'axios';
import { useEffect } from "react";
import {Link, useNavigate } from "react-router-dom";

const Frequently = () => {
  const [faqData, setFaqData] = useState([]);
  const getFAQData = () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/faqs`)
    .then(response => {
      console.log("FAqData", response.data);
      setFaqData(response.data)
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  }
  useEffect(() => {
    getFAQData();
  }, [])
  return (
    <div className="relative">
      <div className="absolute top-[400px] left-0 overflow-hidden overflow-hidden lg:flex hidden">
        <img src={Mask} alt="Mask" />
      </div>
      <div className="absolute top-[100px] right-0">
        <svg width="58" height="83" viewBox="0 0 58 83" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="83" height="83" rx="41.5" transform="matrix(1 0 0 -1 0 83)" fill="#E9EFFF"/>
        </svg>
      </div>
      <div className="absolute top-[120px] right-0">
        <img src={Mask} alt="Mask" />
      </div>
      <div className="absolute right-[150px] lg:flex hidden">
        <svg width="246" height="75" viewBox="0 0 246 75" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="246" y="75" width="246" height="246" rx="123" transform="rotate(180 246 75)" fill="#E9EFFF"/>
        </svg>
      </div>
      <div className="absolute right-[200px] top-20 lg:flex hidden">
        <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="61" y="61" width="61" height="61" rx="30.5" transform="rotate(180 61 61)" fill="#E9EFFF"/>
        </svg>
      </div>
      <div className="absolute right-[500px] top-[150px] ">
        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="31" y="31" width="31" height="31" rx="15.5" transform="rotate(180 31 31)" fill="#E9EFFF"/>
        </svg>
      </div>
      <div className="absolute right-[800px] -bottom-5 z-[10]">
        <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="61" y="61" width="61" height="61" rx="30.5" transform="rotate(180 61 61)" fill="#E9EFFF"/>
        </svg>
      </div>
      <div className="absolute right-0 bottom-5 z-[10]">
        <svg width="30" height="64" viewBox="0 0 30 64" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="64" height="64" rx="32" fill="#E9EFFF"/>
        </svg>
      </div>
      <div className="pt-20 px-2 xl:px-0 w-[97%] md:w-[90%] mx-auto">
        <div className="flex lg:justify-between justify-center">
          <h1 className="font-extrabold text-[27.69px] lg:text-5xl leading-[28.85px] lg:leading-[3rem] flex justify-center items-center text-center">Frequently Asked Questions</h1>
            <motion.div
              whileHover={{ scale: 1.05 }}
            >
              <Link  to={`/faq`} className="flex items-center gap-1 text-[#B02526] lg:flex hidden">See All FAQ <img className="bubbling-svg" src={ButtonVector} /></Link>
            </motion.div>
        </div>
        {/* <div className="flex justify-between">
          <div className="font-[800] text-[48px] leading-[50px] text-[#14183E]">FREQUENTLY ASKED QUESTION</div>
          <div>
            <div className="flex items-center">
              <div className="text-[#B02526] font-[600] text-[20px] mr-2">See All FAQ</div>
              <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L6 6.5L1 12" stroke="#9A2D2D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </div>
        </div> */}
        <div className="w-full flex flex-col flex-col-reverse lg:flex-row justify-between items-end h-full">
          <div className="w-full lg:w-[50%] lg:max-w-[878px] mb-5">
            <FrequentlyAskQuestion
              faqData={faqData}
            />
          </div>
          <div className="w-full lg:w-[50%] flex justify-center h-full">
            <ImageSection />
          </div>
        </div>
      </div>
    </div>
  )
}
export default Frequently;
