import React, {useState, useEffect } from 'react';
import SuppliersHeroSection from '../components/HeroSection/SuppliersHeroSection';
import Layout from '../components/Layout/Layout';
import RescueBag from '../assets/RescueBag.png';
import Towel from '../assets/towel.png';
import ActiSplint from '../assets/ActiSplint.png';
import backgroundCover from '../assets/Section.png';
import FAQ from "../components/Supplier/FAQ";
import Stories from '../components/Supplier/Stories'
import Subscribe from "../components/Supplier/Subscribe/index.jsx";
import { Link } from "react-router-dom";
import About from '../components/Supplier/About';
import { motion } from 'framer-motion';
import axios from 'axios';

const Suppliers = () => {
  const [heroSection, setHeroSection] = useState([]);
  const [products, setProducts] = useState([]);
  const backgroundStyle = {
    backgroundImage: `url(${backgroundCover})`,
    backgroundSize: 'cover',
  };
  const dropShadowFilter = 'drop-shadow(10px -10px 0px #E5EDFF)';
  useEffect(() => {
    const getBannerData = () => {
      console.log("${process.env.REACT_PUBLIC_BASE_URL}", process.env.REACT_APP_BASE_URL)
      axios.get(`${process.env.REACT_APP_BASE_URL}/supplyBanner`)
      .then(response => {
        setHeroSection(response?.data[0])
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
    }
    const getProducts = () => {
      console.log("${process.env.REACT_PUBLIC_BASE_URL}", process.env.REACT_APP_BASE_URL)
      axios.get(`${process.env.REACT_APP_BASE_URL}/product`)
      .then(response => {
        setProducts(response?.data)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
    }
    getBannerData()
    getProducts()
  }, [])
  return (
    <Layout>
      <div className='overflow-hidden'>
        <div className='flex'>
          <SuppliersHeroSection
            heroSection={heroSection}
          />
        </div>
        <div className=''>
          <div className='w-[90%] mx-auto'>
            <div className='flex justify-between w-full'>
              <div className='w-full font-[800] text-[20px] md:text-[30px] lg:text-[40px] leading-[28.85px] lg:leading-[50px] w-[60%]'>First Aid Kits and Supplies that meet WorkSafeBC Regulations</div>
              <div className='hidden lg:flex items-center gap-2 w-[40%] justify-end'>
                <Link to="/supplies_products" className='text-[#B02526] font-[600] text-[20px] leading-[60px] cursor-pointer'>See All Products</Link>
                <div className='cursor-pointer'>
                  <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.78516 1L6.99988 6.5L1.78516 12" stroke="#B02526" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className='mt-10 w-[90%] mx-auto'>
            <div className='font-[500] text-[12px] md:text-[16px] lg:text-[20px] leading-[30px] text-[#5E6282] mb-4'>Featured products</div>
            <div className='gap-2 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3'>
              {products.length > 0 &&
                products.slice(0, 3).map((items, index) => (
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    className='flex items-center justify-center'
                  >
                    <Link to={`/supplies-product-view?id=${items._id}`} className='rounded-[8px] w-[396px] h-[300px]' style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                      <div className='flex items-center justify-center'>
                        <img
                          src={`${process.env.REACT_APP_BASE_URL}/${items.image}`}
                          alt="Rescue bag"
                        />
                      </div>
                      <div className='font-[800] text-[18.59px] md:text-[20px] leading-[23.42px] md:leading-[25.2px] mt-10 ml-10'>{items.name}</div>
                      <div className='text-[#B02526] font-[600] text-[16px] leading-[16px] mt-5 ml-10'>{items.price}</div>
                    </Link>
                  </motion.div>
                ))
              }
              {/* <motion.div 
                whileHover={{ scale: 1.05 }}
                className='flex items-center justify-center'>
                <div className='rounded-[8px] w-[396px] h-[300px]' style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                  <div className='flex items-center justify-center'>
                    <img src={RescueBag} alt="Rescue bag" />
                  </div>
                  <div className='font-[800] text-[18.59px] md:text-[20px] leading-[23.42px] md:leading-[25.2px] mt-10 ml-10'>WorkSafeBC Basic First Aid Kit</div>
                  <div className='text-[#B02526] font-[600] text-[16px] leading-[16px] mt-5 ml-10'>$29.00 CAD</div>
                </div>
              </motion.div>
              <motion.div  whileHover={{ scale: 1.05 }} className='flex items-center justify-center'>
                <div className='rounded-[8px] w-[396px] h-[300px]' style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                  <div className='flex items-center justify-center'>
                    <img src={Towel} alt="Rescue bag" />
                  </div>
                  <div className='font-[800] text-[18.59px] md:text-[20px] leading-[23.42px] md:leading-[25.2px] mt-10 ml-10'>Mix Fibre Blanket</div>
                  <div className='text-[#B02526] font-[600] text-[16px] leading-[16px] mt-5 ml-10'>$19.00 CAD</div>
                </div>
              </motion.div>
              <motion.div 
                whileHover={{ scale: 1.05 }}
                className='flex items-center justify-center'>
                <div className='rounded-[8px] w-[396px] h-[300px]' style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                  <div className='flex items-center justify-center'>
                    <img src={ActiSplint} alt="Rescue bag" />
                  </div>
                  <div className='font-[800] text-[18.59px] md:text-[20px] leading-[23.42px] md:leading-[25.2px] mt-10 ml-10'>ActiSplint with Sensi-Wrap</div>
                  <div className='text-[#B02526] font-[600] text-[16px] leading-[16px] mt-5 ml-10'>$23.25 CAD</div>
                </div>
              </motion.div> */}
            </div>
            <div className='lg:hidden flex items-center gap-2 w-[40%] justify-end w-full'>
              <Link to="/supplies_products" className='text-[#B02526] font-[600] text-[16px] leading-[60px] cursor-pointer'>See All Products</Link>
              <div className='cursor-pointer'>
                <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.78516 1L6.99988 6.5L1.78516 12" stroke="#B02526" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
            </div>
          </div>
          <div className='mt-10'>
            <About
              heroSection={heroSection}
            />
          </div>
          <div className='mt-10' style={backgroundStyle}>
            <FAQ />
            <div className='p-10'>
              <Stories />
            </div>
          </div>
          <div className='my-10 w-[90%] mx-auto'>
            <Subscribe />
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Suppliers;
